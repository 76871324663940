import { Link } from 'react-router-dom';

import logoMercadoLivre from 'assets/logo-mercado_livre.png';
import logoRac from 'assets/logo-rac.png';

import {
  ButtonNavigate,
  Container,
  ContainerMercadoLivre,
  ImageMercadoLivre,
  ImageRac,
  Main,
  Message,
} from './styles';

const Home: React.FC = () => {
  return (
    <Container>
      <Main>
        <ImageRac alt="Logo Rac Systems" src={logoRac} />

        <ContainerMercadoLivre>
          <Message>Login concluído com sucesso!</Message>
          <ImageMercadoLivre alt="Logo Mercado Livre" src={logoMercadoLivre} />
        </ContainerMercadoLivre>

        <Link to="/auth">
          <ButtonNavigate type="button">OK</ButtonNavigate>
        </Link>
      </Main>
    </Container>
  );
};

export default Home;
