import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    outline: 0;
    padding: 0;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased!important;
  }
`;
