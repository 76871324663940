import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: #ffe700;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  justify-content: center;
`;

export const Main = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-around;
  width: 70%;
`;

export const ImageRac = styled.img`
  width: 100px;
`;

export const ContainerMercadoLivre = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Message = styled.h2`
  color: #1e2e7a;
  font-size: 18pt;
  font-weight: 400;
  padding-bottom: 80px;
  text-align: center;
`;

export const ImageMercadoLivre = styled.img`
  width: 200px;
`;

export const ButtonNavigate = styled.button`
  background-color: #1e2e7a;
  border: 1px solid #1e2e7a;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 20pt;
  height: 50px;
  width: 200px;
`;
