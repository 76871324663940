import { Router } from 'react-router-dom';

import Routes from 'routes';

import navigation from 'services/navigation';

import GlobalStyle from 'styles/global';

const App: React.FC = () => {
  return (
    <>
      <Router history={navigation}>
        <Routes />
      </Router>

      <GlobalStyle />
    </>
  );
};

export default App;
