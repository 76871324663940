import { Switch } from 'react-router-dom';

import Auth from 'pages/Auth';
import Home from 'pages/Home';

import RouteWrapper from './RouteWrapper';

const Routes: React.FC = () => {
  return (
    <Switch>
      <RouteWrapper component={Home} exact path="/" />
      <RouteWrapper component={Auth} exact path="/auth" />
    </Switch>
  );
};

export default Routes;
